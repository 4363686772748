import React, { Component } from 'react';
class Pay extends Component {
  render() {
    return (
      <div>
        <img src='images/nu.jpg' alt='/'></img>
        <img src='images/picpay.jpg' alt='/'/>
      </div>
    );
  }
}

export default Pay;