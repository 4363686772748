import React, { useState } from "react";

import Modal from "react-modal";

Modal.setAppElement("#root");

export default function App({ item }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="App">
      <div className="item-wrap" onClick={toggleModal}>
        <img src={`${item.imgurl}`} className="item-img" alt="modal button"/>
        <div className="overlay">
          <div className="portfolio-item-meta">
            <h5>{item.name}</h5>
            <p>{item.description}</p>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="item-wrap">
          <img src={`${item.imgurl}`} className="item-img" width="10%" alt="modal"/>
          <div className="overlay">
            <div className="portfolio-item-meta">
              <h5>{item.name}</h5>
              <p>{item.description}</p>
            </div>
          </div>
        </div>
        <button onClick={toggleModal}>Close modal</button>
      </Modal>
    </div>
  );
}