let resumeData = {
  "imagebaseurl":"https://ricardoandrade.me/",
  "name": "Ricardo Seiji",
  "surname": "Akiyama de Andrade",
  "fullname": "Ricardo Seiji Akiyama de Andrade",
  "role": "Node.js Developer and Tech Lead",
  "linkedinId":"akiyamaseiji",
  "skypeid": "seiji_akiyama",
  "roleDescription": " This is my personal page.",
  "socialLinks":[
    {
      "name":"linkedin",
      "url":"https://www.linkedin.com/in/akiyamaseiji/",
      "className":"fab fa-linkedin"
    },
    {
      "name":"github",
      "url":"http://github.com/seijiakiyama",
      "className":"fab fa-github"
    },
    {
      "name":"bitbucket",
      "url":"https://bitbucket.org/seijiakiyama/",
      "className":"fab fa-bitbucket"
    },
    {
      "name":"stackoverflow",
      "url":"https://stackoverflow.com/users/6079302/seiji-akiyama",
      "className":"fab fa-stack-overflow"
    },
    {
      "name":"twitter",
      "url":"http://twitter.com/seiji_akiyama",
      "className":"fab fa-twitter"
    },
    {
      "name":"spotify",
      "url":"https://open.spotify.com/user/12142655421",
      "className":"fab fa-spotify"
    },
    {
      "name":"youtube",
      "url":"https://www.youtube.com/channel/UC1EZD-uPqYdMvWhKMGTHc6w",
      "className":"fab fa-youtube"
    },
    {
      "name":"reddit",
      "url":"https://reddit.com/user/seijiakiyama",
      "className":"fab fa-reddit"
    },
    {
      "name":"instagram",
      "url":"http://instagram.com/seiji_akiyama",
      "className":"fab fa-instagram"
    },
    {
      "name":"facebook",
      "url":"https://www.facebook.com/seijiakiyama",
      "className":"fab fa-facebook"
    }
  ],
  "aboutme":"I am Developer that went through Java, ActionScript3 but Node.js got me. I'm graduated at FATEC (Faculdade de Tecnologia) but I keep myself updated. I work for a Brazilian e-commerce company, hired through a specialized IT consulting firm. Still, my favorite thing to do is being able to spend my time with who I love. Work hard, love harder, right? Call me cheesy, I really don't mind 😄",
  "address":"SJC - Brazil",
  "website":"https://ricardoandrade.me",
  "education":[
    {
      "UniversityName":"Faculdade de Tecnologia",
      "specialization":"System Analysis and Development",
      "MonthOfPassing":"Aug",
      "YearOfPassing":"2018",
      "Achievements":""
    }
  ],
  "work":[
    {
      "CompanyName":"GSW",
      "specialization":"Node.js/Angular Developer",
      "MonthOfLeaving":"Jan",
      "YearOfLeaving":"2018",
      "Achievements":""
    }
  ],
  "skillsDescription":"I've been working with Javascript and MongoDB, but I'm familiar with",
  "allSkills": ["JavaScript", "Docker", "MongoDB", "Redis", "Git", "MicroServices", "AWS",
    "Heroku", "Unit Testing", "CI"],
  "skills":[
    {
      "skillName":"Node.js",
      "skillLevel": "eightyPercent"
    },
    {
      "skillName":"MongoDB",
      "skillLevel": "seventyPercent"
    },
    {
      "skillName":"Git",
      "skillLevel": "seventyPercent"
    },
    {
      "skillName":"Bad Jokes",
      "skillLevel": "oneHundredPercent"
    }
  ],
  "portfolio":[
    {
      "name":"Back4App",
      "description":"backend",
      "imgurl":"images/portfolio/back4app.png"
    }
  ],
  "testimonials":[
    {
      "description":"I love him!",
      "name":"Brito, Camila"
    }
  ]
}

export default resumeData
