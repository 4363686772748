import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
              Contact me! I'll really appreciate it!
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4>LinkedIn: <a target='_blank' href={'https://www.linkedin.com/in/akiyamaseiji/'} rel="noreferrer">{resumeData.linkedinId}</a></h4>
                <h4>Email: <a target='_blank' href={'mailto:contact@ricardoandrade.me'} rel="noreferrer">contact@ricardoandrade.me</a></h4>
                <h4>Telegram: <a target='_blank' href={'https://t.me/seijiakiyama'} rel="noreferrer">@seijiakiyama</a></h4>
                <h4>WhatApp: <a target='_blank' href={'https://wa.me/5512981551982'} rel="noreferrer">(+55 12) 98155-1982</a></h4>
              </div>
            </aside>
          </div>
        </section>
        );
  }
}