import React, { Component } from "react"
import {
  Route,
  BrowserRouter,
  Switch
} from "react-router-dom"
import App from "./App"
import Pay from "./Pay"
import FourOhFour from "./404"

class Main extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content">
          <Switch>
            <Route path="/" exact={true} component={App}/>
            <Route path="/pay" component={Pay}/>
            <Route component={FourOhFour} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default Main;