import React, { Component } from 'react';
class FourOhFour extends Component {
  render() {
    return (
      <p className="404" style={{
        width: '0px',
        height: '0px',

        position: 'absolute',
        top:0,
        bottom: 0,
        left: 0,
        right: 0,

        margin: 'auto',
        'fontSize': 300
      }}>
        404
      </p>
    );
  }
}

export default FourOhFour;